// Layout (card) module's mixin definitions are here. For the module itself
// check `_layout.scss`.
// Mixin for alternate card sizes:
//  $card-alt-size-name: The name of the class used for the alternate size card.
//  $card-alt-size-width: The width of the alternate size card.
@mixin make-card-alt-size ($card-alt-size-name, $card-alt-size-width) {
  @if type-of($card-alt-size-width) == 'number' and unit($card-alt-size-width) == '%' {
    .#{$card-name}.#{$card-alt-size-name} {
      max-width: $card-alt-size-width;
      width: auto;
    }
  }
  @else {
    @media screen and (min-width: #{$card-alt-size-width}) {
      .#{$card-name}.#{$card-alt-size-name} {
        max-width: $card-alt-size-width;
      }
    }
  }
}
// Mixin for alternate cards (card color variants):
//  $card-alt-name: The name of the class used for the alternate card.
//  $card-alt-back-color: The background color of the alternate card.
//  $card-alt-fore-color: The text color of the alternate card.
//  $card-alt-border-color: The border style of the alternate card.
@mixin make-card-alt-color ($card-alt-name, $card-alt-back-color : $card-back-color,
  $card-alt-fore-color : $card-fore-color, $card-alt-border-color : $card-border-color) {
  .#{$card-name}.#{$card-alt-name} {
    @if $card-alt-back-color != $card-back-color {
      #{$card-back-color-var}: $card-alt-back-color;
    }
    @if $card-alt-fore-color != $card-fore-color {
      #{$card-fore-color-var}: $card-alt-fore-color;
    }
    @if $card-alt-border-color != $card-border-color {
      #{$card-border-color-var}: $card-alt-border-color;
    }
  }
}
// Mixin for alternate card sections (card section color variants):
//  $card-section-alt-name: The name of the class used for the alternate card section.
//  $card-section-alt-back-color: The background color of the alternate card section.
//  $card-section-alt-fore-color: The text color of the alternate card section.
@mixin make-card-section-alt-color ($card-section-alt-name, $card-section-alt-back-color : $card-back-color,
  $card-section-alt-fore-color : $card-fore-color) {
  .#{$card-name} > .#{$card-section-name}.#{$card-section-alt-name} {
    @if $card-section-alt-back-color != $card-back-color {
      #{$card-back-color-var}: $card-section-alt-back-color;
    }
    @if $card-section-alt-fore-color != $card-fore-color {
      #{$card-fore-color-var}: $card-section-alt-fore-color;
    }
  }
}
// Mixin for alternate card sections (card section padding variants):
//  $card-section-alt-name: The name of the class used for the alternate card section.
//  $card-section-alt-padding: The padding of the alternate card section.
@mixin make-card-section-alt-style ($card-section-alt-name, $card-section-alt-padding) {
  .#{$card-name} > .#{$card-section-name}.#{$card-section-alt-name} {
    padding: $card-section-alt-padding;
  }
}
