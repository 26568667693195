/*
  Definitions for forms and input elements.
*/
// Different elements are styled based on the same set of rules.
$input-group-name:           'input-group' !default;  // Class name for input groups.
$_include-fluid-input-group: true !default;           // [Hidden] Should fluid input groups be included? (boolean)
$input-group-fluid-name:     'fluid' !default;        // Class name for fluid input groups.
$input-group-vertical-name:  'vertical' !default;     // Class name for vertical input groups.
$input-group-mobile-breakpoint:   767px !default;     // Breakpoint for fluid input group mobile view.
$button-class-name:           'button' !default;      // Class name for elements styled as buttons.
$input-disabled-opacity:      0.75 !default;          // Opacity for input elements when disabled.
$button-group-name:         'button-group' !default;  // Class name for button groups.
$button-group-mobile-breakpoint: 767px !default;      // Mobile breakpoint for button groups.
$form-back-color:             #f0f0f0 !default;       // Background color for forms.
$form-fore-color:             #111 !default;          // Text color for forms.
$form-border-color:           #ddd !default;          // Border color for forms.
$input-back-color:            #f8f8f8 !default;       // Background color for input elements.
$input-fore-color:            #111 !default;          // Text color for input elements.
$input-border-color:          #ddd !default;          // Border color for input elements.
$input-focus-color:           #0288d1 !default;       // Border color for focused input elements.
$input-invalid-color:         #d32f2f !default;       // Border color for invalid input elements.
$button-back-color:           #e2e2e2 !default;       // Background color for buttons.
$button-hover-back-color:     #dcdcdc !default;       // Background color for buttons (hover).
$button-fore-color:           #212121 !default;       // Text color for buttons.
$button-border-color:         transparent !default;   // Border color for buttons.
$button-hover-border-color:   transparent !default;   // Border color for buttons (hover).
$button-group-border-color:  rgba(124,124,124, 0.54) !default;  // Border color for button groups.
// CSS variable name definitions [exercise caution if modifying these]
$form-back-color-var:         '--form-back-color' !default;
$form-fore-color-var:         '--form-fore-color' !default;
$form-border-color-var:       '--form-border-color' !default;
$input-back-color-var:        '--input-back-color' !default;
$input-fore-color-var:        '--input-fore-color' !default;
$input-border-color-var:      '--input-border-color' !default;
$input-focus-color-var:       '--input-focus-color' !default;
$input-invalid-color-var:     '--input-invalid-color' !default;
$button-back-color-var:       '--button-back-color' !default;
$button-hover-back-color-var: '--button-hover-back-color' !default;
$button-fore-color-var:       '--button-fore-color' !default;
$button-border-color-var:       '--button-border-color' !default;
$button-hover-border-color-var: '--button-hover-border-color' !default;
$button-group-border-color-var: '--button-group-border-color' !default;
// == Uncomment below code if this module is used on its own ==
//
// $base-font-size:          1rem !default;        // Font sizing for all elements
// $universal-margin:        0.5rem !default;      // Universal margin for the most elements
// $universal-padding:       0.5rem !default;      // Universal padding for the most elements
// $universal-border-radius: 0.125rem !default;    // Universal border-radius for most elements
// $universal-box-shadow:    none !default;        // Universal box-shadow for most elements
// $universal-margin-var:          '--universal-margin' !default;
// $universal-padding-var:         '--universal-padding' !default;
// $universal-border-radius-var:   '--universal-border-radius' !default;
// $universal-box-shadow-var:      '--universal-box-shadow' !default;
// :root {
//  #{$universal-margin-var}: $universal-margin;
//  #{$universal-padding-var}: $universal-padding;
//  #{$universal-border-radius-var}: $universal-border-radius;
//  @if $universal-box-shadow != none {
//   #{$universal-box-shadow-var}: $universal-box-shadow;
//  }
// }
//
// ============================================================
// Check the `_input_control_mixins.scss` file to find this module's mixins.
@import 'input_control_mixins';
/* Input_control module CSS variable definitions */
:root {
  #{$form-back-color-var}: $form-back-color;
  #{$form-fore-color-var}: $form-fore-color;
  #{$form-border-color-var}: $form-border-color;
  #{$input-back-color-var}: $input-back-color;
  #{$input-fore-color-var}: $input-fore-color;
  #{$input-border-color-var}: $input-border-color;
  #{$input-focus-color-var}: $input-focus-color;
  #{$input-invalid-color-var}: $input-invalid-color;
  #{$button-back-color-var}: $button-back-color;
  #{$button-hover-back-color-var}: $button-hover-back-color;
  #{$button-fore-color-var}: $button-fore-color;
  #{$button-border-color-var}: $button-border-color;
  #{$button-hover-border-color-var}: $button-hover-border-color;
  #{$button-group-border-color-var}: $button-group-border-color;
}
// Base form styling
form {  // Text color is the default, this can be changed manually.
  background: var(#{$form-back-color-var});
  color: var(#{$form-fore-color-var});
  border: $__1px solid var(#{$form-border-color-var});
  border-radius: var(#{$universal-border-radius-var});
  margin: var(#{$universal-margin-var});
  padding: calc(2 * var(#{$universal-padding-var})) var(#{$universal-padding-var});
  @if $universal-box-shadow != none {
    box-shadow: var(#{$universal-box-shadow-var});
  }
}
// Fieldset styling
fieldset {
  // Apply always to overwrite defaults for all of the below.
  border: $__1px solid var(#{$form-border-color-var});
  border-radius: var(#{$universal-border-radius-var});
  margin: calc(var(#{$universal-margin-var}) / 4);
  padding: var(#{$universal-padding-var});
}
// Legend styling.
legend {
  // Edge fixes.
  box-sizing: border-box;
  display: table;
  max-width: 100%;
  white-space: normal;
  // Actual styling.
  font-weight: $bold-font-weight;
  padding: calc(var(#{$universal-padding-var}) / 2);
}
// Label syling. - Basically just padding, but there might be more in the future.
label {
  padding: calc(var(#{$universal-padding-var}) / 2) var(#{$universal-padding-var});
}
// Input group styling.
.#{$input-group-name} {
  display: inline-block;
  // Fluid input groups
  @if $_include-fluid-input-group {
    &.#{$input-group-fluid-name} {
      display: flex;
      align-items: center;
      justify-content: center;
      & > input {
        max-width: 100%;
        flex-grow: 1;
        flex-basis: 0px;
      }
      // On mobile
      @media screen and (max-width: #{$input-group-mobile-breakpoint}) {
        align-items: stretch;
        flex-direction: column;
      }
    }
    // Vertical input groups
    &.#{$input-group-vertical-name} {
      display: flex;
      align-items: stretch;
      flex-direction: column;
      & > input {
        max-width: 100%;
        flex-grow: 1;
        flex-basis: 0px;
      }
    }
  }
}
// Correct the cursor style of increment and decrement buttons in Chrome.
[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}
// Correct style in Chrome and Safari.
[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
// Correct style in Chrome and Safari.
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
// Common textual input styling. - Avoid using box-shadow with these.
input:not([type]), [type="text"], [type="email"], [type="number"], [type="search"],
[type="password"], [type="url"], [type="tel"], [type="checkbox"], [type="radio"], textarea, select {
  box-sizing: border-box;
  // Background, color and border should not be unassigned, as the browser defaults will apply.
  background: var(#{$input-back-color-var});
  color: var(#{$input-fore-color-var});
  border: $__1px solid var(#{$input-border-color-var});
  border-radius: var(#{$universal-border-radius-var});
  margin: calc(var(#{$universal-margin-var}) / 2);
  padding: var(#{$universal-padding-var}) calc(1.5 * var(#{$universal-padding-var}));
}
// Hover, focus, disabled, readonly, invalid styling for common textual inputs.
input:not([type="button"]):not([type="submit"]):not([type="reset"]), textarea, select {
  &:hover, &:focus {
    border-color: var(#{$input-focus-color-var});
    box-shadow: none;
  }
  &:invalid, &:focus:invalid{
    border-color: var(#{$input-invalid-color-var});
    box-shadow: none;
  }
  &[readonly]{
    background: var(#{$secondary-back-color-var});
  }
}
// Fix for select and option elements overflowing their parent container.
select {
  max-width: 100%;
}
option {
  overflow: hidden;
  text-overflow: ellipsis;
}
// Styling for checkboxes and radio buttons.
[type="checkbox"], [type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
  height: calc(#{$base-font-size} + var(#{$universal-padding-var}) / 2);
  width: calc(#{$base-font-size} + var(#{$universal-padding-var}) / 2);
  vertical-align: text-bottom;
  padding: 0; // Remove padding added from previous styles.
  flex-basis: calc(#{$base-font-size} + var(#{$universal-padding-var}) / 2) !important; // Override fluid input-group styling.
  flex-grow: 0 !important;  // Using with fluid input-groups is not recommended.
  &:checked:before {
    position: absolute;
  }
}
[type="checkbox"] {
  &:checked:before {
    content: '\2713';
    font-family: sans-serif;
    font-size: calc(#{$base-font-size} + var(#{$universal-padding-var}) / 2);
    top: calc(0rem - var(#{$universal-padding-var}));
    left: calc(var(#{$universal-padding-var}) / 4);
  }
}
[type="radio"] {
  border-radius: 100%;
  &:checked:before {
    border-radius: 100%;
    content: '';
    top: calc(#{$__1px} + var(#{$universal-padding-var}) / 2);
    left: calc(#{$__1px} + var(#{$universal-padding-var}) / 2);
    background: var(#{$input-fore-color-var});
    width: 0.5rem;
    height: 0.5rem;
  }
}
// Placeholder styling (keep browser-specific definitions separated, they do not play well together).
:placeholder-shown {
  color: var(#{$input-fore-color-var});
}
::-ms-placeholder {
  color: var(#{$input-fore-color-var});
  opacity: 0.54;
}
// Definitions for the button and button-like elements.
// Different elements are styled based on the same set of rules.
// Reset for Firefox focusing on button elements.
button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
// Fixes for Android 4, iOS and Safari.
button, html [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}
// Other fixes.
button {
  overflow: visible;      // Show the overflow in IE.
  text-transform: none;    // Remove inheritance of text-transform in Edge, Firefox, and IE.
}
// Default styling
button, [type="button"], [type="submit"], [type="reset"],
a.#{$button-class-name}, label.#{$button-class-name}, .#{$button-class-name},
a[role="button"], label[role="button"], [role="button"] {
  display: inline-block;
  background: var(#{$button-back-color-var});
  color: var(#{$button-fore-color-var});
  border: $__1px solid var(#{$button-border-color-var});
  border-radius: var(#{$universal-border-radius-var});
  padding: var(#{$universal-padding-var}) calc(1.5 * var(#{$universal-padding-var}));
  margin: var(#{$universal-margin-var});
  text-decoration: none;
  cursor: pointer;
  transition: background 0.3s;
  &:hover, &:focus {
    background: var(#{$button-hover-back-color-var});
    border-color: var(#{$button-hover-border-color-var});
  }
}
// Disabled styling for input and button elements.
input, textarea, select, button, .#{$button-class-name}, [role="button"] {
  // .button[disabled] is actually higher specificity than a.button, so no need for more than that
  &:disabled, &[disabled] {
    cursor: not-allowed;
    opacity: $input-disabled-opacity;
  }
}
// Button group styling.
.#{$button-group-name} {
  display: flex;
  border: $__1px solid var(#{$button-group-border-color-var});
  border-radius: var(#{$universal-border-radius-var});
  margin: var(#{$universal-margin-var});
  @if $universal-box-shadow != none {
    box-shadow: var(#{$universal-box-shadow-var});
  }
  & > button, [type="button"], & > [type="submit"], & > [type="reset"],
  & > .#{$button-class-name}, & > [role="button"] {
    margin: 0;
    max-width: 100%;
    flex: 1 1 auto;
    text-align: center;
    border: 0;
    border-radius: 0;
    box-shadow: none;
  }
  & > :not(:first-child) {
    border-left: $__1px solid var(#{$button-group-border-color-var});
  }
  // Responsiveness for button groups
  @media screen and (max-width: #{$button-group-mobile-breakpoint}) {
    flex-direction: column;
    & > :not(:first-child) {
      border: 0;    // Reapply to remove the left border from elements.
      border-top: $__1px solid var(#{$button-group-border-color-var});
    }
  }
}
